import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavUserPreferenceEnum } from '../../auth/services/merchant.service';

@Injectable()
export class SideNavBarPreferenceService {
  private sideNavBarPreferenceSubject =
    new BehaviorSubject<NavUserPreferenceEnum>(null);

  public sideNavBarPreference$: Observable<NavUserPreferenceEnum> =
    this.sideNavBarPreferenceSubject.asObservable();

  setValue(shouldRefresh: NavUserPreferenceEnum): void {
    this.sideNavBarPreferenceSubject.next(shouldRefresh);
  }

  getValue(): NavUserPreferenceEnum {
    return this.sideNavBarPreferenceSubject.value;
  }
}
