import {
  Promotion,
  PromotionType,
  PromotionItemType,
  SimplePromotion,
  ActiveAdvancePromotion,
  ActiveSimplePromotion,
  LineItem,
} from './types';
import _ from 'lodash';

export class PromotionService {
  public mapSimplePromotionToVariant(
    variant: any,
    variantPromos: ActiveSimplePromotion,
  ): any {
    const simplePromotion: SimplePromotion | undefined =
      variantPromos.variantSpecific.find((promo: SimplePromotion) =>
        promo.promotionVariants.includes(variant.id),
      ) || variantPromos.forAllProducts;

    if (!simplePromotion) {
      return variant;
    }
    const advancePromotion = {
      id: simplePromotion.id,
      name: simplePromotion.name,
      startDate: simplePromotion.startDate,
      endDate: simplePromotion.endDate,
      type: simplePromotion.type,
      discountType: simplePromotion.discountType,
      amount: simplePromotion.amount,
      isAll: simplePromotion.isAllProducts || 0,
      itemIds: simplePromotion.promotionVariants || [],
      itemType: PromotionItemType.Product,
      quantity: 0,
    };
    variant.promotion = advancePromotion;
    return variant;
  }

  public mapPromotionToVariant(
    variantCategoryIds: number[],
    variant: any,
    variantPromos: ActiveAdvancePromotion,
  ): any {
    variant.promotion = variantPromos.productSpecific?.find(
      (promo: Promotion) => promo.itemIds?.includes(variant.id),
    );
    if (variant.promotion) return variant;

    variant.promotion = variantPromos.categorySpecific?.find(
      (promo: Promotion) =>
        _.intersection(promo.itemIds || [], variantCategoryIds).length,
    );
    if (variant.promotion) return variant;

    variant.promotion = variantPromos.forAllProducts?.[0];
    return variant;
  }

  public getUniqueLineItems(lineItems: LineItem[]): LineItem[] {
    const uniqueLineItems: LineItem[] = [];
    lineItems.forEach((item) => {
      let existingItem = uniqueLineItems.find((uniqueItem) => {
        /**
         * Extra case
         */
        const isSameVariant = uniqueItem.variantId === item.variantId;
        /**
         * Same conditonal promo case
         */
        const isSameCategoryPromoVariant =
          uniqueItem.promotion?.id &&
          item.promotion?.id &&
          uniqueItem.promotion.id === item.promotion.id &&
          item.promotion.type === PromotionType.Conditional &&
          item.promotion.itemType === PromotionItemType.Category;

        const isAllProductXPromoVariant =
          uniqueItem.promotion?.id &&
          item.promotion?.id &&
          uniqueItem.promotion.id === item.promotion.id &&
          item.promotion.type === PromotionType.Conditional &&
          item.promotion.isAll;

        return (
          isSameVariant ||
          isSameCategoryPromoVariant ||
          isAllProductXPromoVariant
        );
      });
      if (existingItem) {
        existingItem.quantity += item.quantity;
        existingItem.xLineItemQuantities?.push({
          lineItemId: item.lineItemId,
          variantId: item.variantId,
          quantity: item.quantity,
          price: item.priceExclusive,
        });
      } else {
        existingItem = item;
        existingItem.xLineItemQuantities = [
          {
            lineItemId: item.lineItemId,
            variantId: item.variantId,
            quantity: item.quantity,
            price: item.priceExclusive,
          },
        ];
        uniqueLineItems.push(existingItem);
      }
    });
    return uniqueLineItems;
  }
}

export const promotionService = new PromotionService();
