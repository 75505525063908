import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Intercom } from 'ng-intercom';
import { RewaaApiMeta, RewaaBaseApiResponse } from '@rewaa-team/types';
import { Store } from '@ngrx/store';
import { RegisterEnums } from '@rewaa-team/pos-sdk';
import { ErrorMessages } from '../constants';
import { CustomToastService } from '../services/custom-toast.service';
import { OnlineOfflineService } from '../services/offline/online-offline.service';
import { SpinnerService } from '../services/spinner.service';
import { environment } from '../../../environments/environment';
import { UserAuthenticationService } from '../../auth/services/user-authentication.service';
import { AppState } from '../../reducers';
import { LogoutAction } from '../../reducers/logout/logout.action';

export const BYPASS_NORMAL = new HttpContextToken(() => false);

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private customToast: CustomToastService,
    public translate: TranslateService,
    private onlineOfflineService: OnlineOfflineService,
    private spinnerService: SpinnerService,
    private intercom: Intercom,
    private store: Store<AppState>,
    private userAuthenticationService: UserAuthenticationService,
  ) {}

  private redirectToLogin() {
    this.store.dispatch(new LogoutAction());
    this.userAuthenticationService.logout();
    this.router.navigate(['/login']).then();
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.url.includes('enigma') && err.status !== 401) {
          const rewaaError = err.error as RewaaBaseApiResponse<
            never,
            RewaaApiMeta
          >;
          (rewaaError.meta?.msgs || []).forEach((msg) => {
            this.customToast.warning(msg);
          });
        }
        if (this.onlineOfflineService.isOnline) {
          if (err.status === 401) {
            if (err?.error?.code === 401.1) {
              this.customToast.error(
                this.translate.instant(ErrorMessages.MissingTenantId),
              );
            }
            if (err.url.startsWith(environment.applicationUrl)) {
              this.redirectToLogin();
            } else {
              this.customToast.error(
                this.translate.instant(
                  err.message || 'externalChannel.unauthError',
                ),
              );
            }
          } else if (err.status === 403) {
            if (
              err.url.includes('isreseller') ||
              err.url.includes('custom-field-service') ||
              err.url.includes('pos-service')
            ) {
              return;
            }
            if (err.url.startsWith(environment.applicationUrl)) {
              this.customToast.error(
                this.translate.instant(ErrorMessages.DisabledAccount),
              );
              this.redirectToLogin();
            } else {
              this.customToast.error(
                this.translate.instant(
                  err.message || 'externalChannel.unauthError',
                ),
              );
              this.intercom.show();
            }
          } else if (request.context.get(BYPASS_NORMAL)) {
            return throwError(() => err);
          } else if (err.status === 404) {
            // responseMessage = ErrorMessages.PageNotFound;
            this.spinnerService.stopLoading();
            return throwError(err);
            // this.spinnerService.stopLoading();
            // return of(new HttpResponse({ status: err.status, statusText: 'Not Found' }));
            // return of(request);
          } else if (
            err.status === 406 ||
            err.status === 409 ||
            err.status === 400
          ) {
            if (err.error.name === 'USER INPUT ERROR') {
              // Exposing error message from Zod's response
              (
                err.error.errors as {
                  message: string;
                }[]
              ).forEach((error) => {
                this.customToast.error(this.translate.instant(error.message));
              });
            } else if (
              [
                RegisterEnums.ErrorCodeConstant.ReportGenerationInProgress,
                RegisterEnums.ErrorCodeConstant.RegisterReportCapacityExceeded,
              ].includes(err.error.error)
            ) {
              // TODO: will be removed when cash-management-v2 is live for all users and every shift has a report
              this.customToast.info(
                this.translate.instant(
                  'registerReport.registerReportInProgress',
                ),
              );
            } else if (Array.isArray(err.error.message)) {
              err.error.message.forEach((message) => {
                this.customToast.error(this.translate.instant(message));
              });
            } else {
              this.customToast.error(this.translate.instant(err.error.message));
            }
          } else if (err.status === 422) {
            this.customToast.error(
              this.translate.instant(
                this.translate.instant(err.error.message) || err.error.code,
              ),
            );
          }
        }

        return throwError(() => err);
      }),
      finalize(() => {
        this.spinnerService.stopLoading();
      }),
    );
  }
}
