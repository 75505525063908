export enum DefaultAccountHeadsReferenceURI {
  Liability = 'liability',
  CurrentLiabilities = 'curr-liabilities',
  Assets = 'assets',
  CurrentAssets = 'current-assets',
  NonCurrentAssets = 'non-current-assets',
  CashAndCashEquivalents = 'cash-cash-eqv',
  CashOnHand = 'cash-on-hand',
  CashInBank = 'cash-in-bank',
  CashOtherBankAccount = 'cash-other-bank-acc',
  CashInBankZidOrders = 'cash-in-bank-zid-orders',
  CashInBankSallaOrders = 'cash-in-bank-salla-orders',
  PettyCash = 'cash',
  CashOther = 'cash-other',
  Inventory = 'inventory',
  AccountReceivable = 'account-receivable',
  AccountPayable = 'account-payable',
  AccountReceivableCustomerUnsettled = 'account-receivable-customer-unsettled',
  AccountPayableSupplierUnsettled = 'account-payable-supplier-unsettled',
  Sales = 'sales',
  CostOfGoodsSold = 'cost-of-goods-sold',
  Equity = 'owners-equity',
  VATPayable = 'vat-payable',
  AdvanceToEmployees = 'advance-to-employees',
  PrepaidExpenses = 'prepaid-expenses',
  PrepaidRent = 'prepaid-rent',
  PrepaidMedicalInsurance = 'prepaid-medical-insurance',
  PropertyPlantAndEquipment = 'property-plant-eqp',
  IssuedCapital = 'issued-capital',
  OpeningBalance = 'opening-balance',
  RetainedEarnings = 'retained-earnings',
  AccruedSalaries = 'accrued-salaries',
  AccruedExpenses = 'accrued-expenses',
  ShortTermLoans = 'short-term-loans',
  ReconcileAdjustments = 'reconcile-adjustments',
  Overpayments = 'overpayments',
  NonCurrentLiabilities = 'non-curr-liabilities',
  LongTermLoans = 'long-term-loans',
  EndOfServicesProvision = 'end-of-services-provision',
  Revenue = 'revenue',
  Expenses = 'expenses',
  DirectCost = 'direct-cost',
  SalesCommission = 'sales-commission',
  ShippingAndCustomFees = 'ship-custom-fees',
  NonOperationalExpenses = 'non-operational-expenses',
  Zakat = 'zakat',
  OperationalCost = 'operational-cost',
  SalariesAndAdministrativeFees = 'sales-administrative-fees',
  MedicalInsuranceAndTreatment = 'med-ins-and-treatment',
  MarketingAndAdvertising = 'market-adv',
  RentalExpenses = 'rental-expenses',
  CommissionsAndIncentives = 'commissions-incentives',
  TravelExpenses = 'travel-expense',
  Treasury = 'Treasury',
  SocialInsuranceExpense = 'social-ins-expense',
  GovernmentFees = 'government-fees',
  FeesAndSubscriptions = 'fees-and-subscriptions',
  UtilitiesExpenses = 'utilities-expenses',
  StationeryAndPrints = 'stationery-prints',
  HospitalityAndCleanliness = 'hospitality-cleanliness',
  BankCommissions = 'bank-commissions',
  OtherExpenses = 'other-expenses',
  Depreciation = 'depreciation',
}

export enum AggregationReferenceURIs {
  // TODO: Rename this to agg-sales
  Sales = 'sales',
  // TODO: Rename this to agg-sales-returns
  SalesReturns = 'sales-returns',
}

export enum OnlineOrderChannels {
  Zid = 'zid',
  Salla = 'salla',
}

export const BankSupportedPaymentMethods: string[] = [
  'Card',
  'SoftPos',
  'Other',
];

export enum AccountHeadState {
  Active = 'active',
  Archived = 'archived',
  DeActivated = 'deactivated',
}

export const expenseCategoryHeads: string[] = [
  DefaultAccountHeadsReferenceURI.SalariesAndAdministrativeFees,
  DefaultAccountHeadsReferenceURI.MedicalInsuranceAndTreatment,
  DefaultAccountHeadsReferenceURI.MarketingAndAdvertising,
  DefaultAccountHeadsReferenceURI.RentalExpenses,
  DefaultAccountHeadsReferenceURI.CommissionsAndIncentives,
  DefaultAccountHeadsReferenceURI.TravelExpenses,
  DefaultAccountHeadsReferenceURI.SocialInsuranceExpense,
  DefaultAccountHeadsReferenceURI.GovernmentFees,
  DefaultAccountHeadsReferenceURI.FeesAndSubscriptions,
  DefaultAccountHeadsReferenceURI.UtilitiesExpenses,
  DefaultAccountHeadsReferenceURI.StationeryAndPrints,
  DefaultAccountHeadsReferenceURI.HospitalityAndCleanliness,
  DefaultAccountHeadsReferenceURI.BankCommissions,
  DefaultAccountHeadsReferenceURI.OtherExpenses,
  DefaultAccountHeadsReferenceURI.Depreciation,
];

export const otherSystemUtilizedHeads: string[] = [
  DefaultAccountHeadsReferenceURI.PettyCash,
  DefaultAccountHeadsReferenceURI.CashInBankZidOrders,
  DefaultAccountHeadsReferenceURI.CashInBankSallaOrders,
  DefaultAccountHeadsReferenceURI.CashOtherBankAccount,
  DefaultAccountHeadsReferenceURI.CashOther,
  DefaultAccountHeadsReferenceURI.AccountPayable,
  DefaultAccountHeadsReferenceURI.AccountReceivable,
  DefaultAccountHeadsReferenceURI.VATPayable,
  DefaultAccountHeadsReferenceURI.IssuedCapital,
  DefaultAccountHeadsReferenceURI.OpeningBalance,
  DefaultAccountHeadsReferenceURI.Sales,
  DefaultAccountHeadsReferenceURI.CostOfGoodsSold,
  DefaultAccountHeadsReferenceURI.CashOnHand,
  DefaultAccountHeadsReferenceURI.CashInBank,
  DefaultAccountHeadsReferenceURI.Inventory,
  DefaultAccountHeadsReferenceURI.ReconcileAdjustments,
  DefaultAccountHeadsReferenceURI.Overpayments,
];

export const nonPaymentMethodLinkableHeads: string[] = [
  DefaultAccountHeadsReferenceURI.AccountPayable,
  DefaultAccountHeadsReferenceURI.AccountReceivable,
  DefaultAccountHeadsReferenceURI.IssuedCapital,
  DefaultAccountHeadsReferenceURI.OpeningBalance,
  DefaultAccountHeadsReferenceURI.CashOnHand,
  DefaultAccountHeadsReferenceURI.Inventory,
];

export const systemUtilizedHeads: string[] = [
  ...otherSystemUtilizedHeads,
  ...expenseCategoryHeads,
];

export enum AccountTransactionType {
  Debit = 'Debit',
  Credit = 'Credit',
}

export enum CurrencyCodes {
  SAR = 'SAR',
}

export enum TransactionType {
  POSSell = 'POSSell',
  POSReturn = 'POSReturn',
  OnlineOrderSell = 'OnlineOrderSell',
  OnlineOrderReturn = 'OnlineOrderReturn',
  WalletTransaction = 'WalletTransaction',
  ExpenseCreation = 'ExpenseCreation',
  ExpenseUpdate = 'ExpenseUpdate',
  ExpenseReverseViaUpdate = 'ExpenseReverseViaUpdate',
  PurchaseOrder = 'PurchaseOrder',
  SupplierPayCredit = 'SupplierPayCredit',
  ExpenseDeletion = 'ExpenseDeletion',
  CustomerReceiveDebit = 'CustomerReceiveDebit',
  ReturnStock = 'ReturnStock',
  SupplierReceiveDebit = 'SupplierReceiveDebit',
  RegisterOperation = 'RegisterOperation',
  AddProduct = 'AddProduct',
  DeleteProduct = 'DeleteProduct',
  StockCount = 'StockCount',
  RemoveStock = 'RemoveStock',
  JournalEntry = 'JournalEntry',
  OpeningBalance = 'OpeningBalance',
  ClosingFiscalPeriod = 'ClosingFiscalPeriod',
  TransferStock = 'TransferStock',
  ReopenFiscalPeriod = 'ReopenFiscalPeriod',
  ImportProducts = 'ImportProducts',
  EditProduct = 'EditProduct',
  BalanceTermination = 'BalanceTermination',
  AdjustmentJournalEntry = 'AdjustmentJournalEntry',
}

export enum SupportedTransactionType {
  POSSell = 'POSSell',
  POSReturn = 'POSReturn',
  OnlineOrderSell = 'OnlineOrderSell',
  OnlineOrderReturn = 'OnlineOrderReturn',
  WalletTransaction = 'WalletTransaction',
  ExpenseCreation = 'ExpenseCreation',
  ExpenseUpdate = 'ExpenseUpdate',
  ExpenseReverseViaUpdate = 'ExpenseReverseViaUpdate',
  PurchaseOrder = 'PurchaseOrder',
  SupplierPayCredit = 'SupplierPayCredit',
  ExpenseDeletion = 'ExpenseDeletion',
  CustomerReceiveDebit = 'CustomerReceiveDebit',
  ReturnStock = 'ReturnStock',
  SupplierReceiveDebit = 'SupplierReceiveDebit',
  RegisterOperation = 'RegisterOperation',
  AddProduct = 'AddProduct',
  DeleteProduct = 'DeleteProduct',
  StockCount = 'StockCount',
  RemoveStock = 'RemoveStock',
  JournalEntry = 'JournalEntry',
  OpeningBalance = 'OpeningBalance',
  ClosingFiscalPeriod = 'ClosingFiscalPeriod',
  TransferStock = 'TransferStock',
  ReopenFiscalPeriod = 'ReopenFiscalPeriod',
  ImportProducts = 'ImportProducts',
}

export const syncJEThresholdInDays = 30;

export const deletableTransactionTypes: TransactionType[] = [
  TransactionType.JournalEntry,
  TransactionType.OpeningBalance,
];

export enum JobStatus {
  InProgress = 'in-progress',
  Completed = 'completed',
}

export enum TransactionMappingEffectType {
  PastAndFuture = 'past-and-future',
  FutureOnly = 'future-only',
}

export enum TransactionMappingType {
  ImportAddDeleteProduct = 'ImportAddDeleteProduct',
  StockCount = 'StockCount',
  RemoveStock = 'RemoveStock',
}

export enum TransactionMappingInternalTypes {
  PaymentMethod = 'PaymentMethod',
}

export type TransactionMappingSuperType =
  | TransactionMappingType
  | TransactionMappingInternalTypes;

export const defaultAccountHeadReferenceURIPerTransactionType = new Map<
  TransactionMappingType,
  string
>([
  [
    TransactionMappingType.ImportAddDeleteProduct,
    DefaultAccountHeadsReferenceURI.IssuedCapital,
  ],
  [
    TransactionMappingType.StockCount,
    DefaultAccountHeadsReferenceURI.CostOfGoodsSold,
  ],
  [
    TransactionMappingType.RemoveStock,
    DefaultAccountHeadsReferenceURI.CostOfGoodsSold,
  ],
]);

export enum AccountingCollectionId {
  TransactionMappingJobStatus = 'transactionMappingJobStatus',
}
