import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TimeOptions, TimeTypes } from '../../types';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rw-table-time-selector',
  templateUrl: './table-time-selector.component.html',
  styleUrls: ['./table-time-selector.component.scss']
})
export class TableTimeSelectorComponent implements OnInit {

  @Input() isToday: boolean = false;

  @Input() selectedCustomDateFilter: object | undefined;

  @Output() timeRangeChanged: EventEmitter<Date[]> = new EventEmitter<Date[]>();

  @Output() isTimeRangeValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  startDateTime: Date;

  endDateTime: Date;

  isDateTimeRangeValid: boolean = true;

  timeOptions: TimeOptions[] = [];

  activeStartTimeOption: TimeTypes = TimeTypes.AM;

  activeStartTimeSelectOption: TimeTypes = TimeTypes.AM;

  activeEndTimeOption: TimeTypes = TimeTypes.AM;

  activeEndTimeSelectOption: TimeTypes = TimeTypes.AM;

  constructor(private translateService: TranslateService, private cd: ChangeDetectorRef){}

  onTimeRangeChange(): void {
    this.startDateTime?.getHours() >= 12 ? this.activeStartTimeOption = TimeTypes.PM : this.activeStartTimeOption = TimeTypes.AM;

    this.endDateTime?.getHours() >= 12 ? this.activeEndTimeOption = TimeTypes.PM : this.activeEndTimeOption = TimeTypes.AM;

    this.activeStartTimeSelectOption = this.activeStartTimeOption;

    this.activeEndTimeSelectOption = this.activeEndTimeOption;

    if (!this.startDateTime || !this.endDateTime) {
      return;
    }

    this.startDateTime.setSeconds(0, 0);
    this.endDateTime.setSeconds(0, 0);

    if(this.startDateTime >= this.endDateTime) {
      this.isDateTimeRangeValid = false;
      this.isTimeRangeValid.emit(this.isDateTimeRangeValid);
      return;
    }
    this.isDateTimeRangeValid = false;

    const timeRange: Date[] = [this.startDateTime, this.endDateTime];
    this.timeRangeChanged.emit(timeRange);
  }

  ngOnInit(): void {
    this.initializeTimeOptions();
    this.initializeSelectedDateTimeOptions();

  }

  private initializeSelectedDateTimeOptions(): void {
    if (this.selectedCustomDateFilter && Object.keys(this.selectedCustomDateFilter).length > 0) {
      const firstEntry = Object.entries(this.selectedCustomDateFilter)[0];
      this.startDateTime = firstEntry[1][0];
      this.endDateTime = firstEntry[1][1];
    }
  }

  private initializeTimeOptions() {
    this.timeOptions = [
      {
        label: this.translateService.instant('AM'),
        value: TimeTypes.AM,
      },
      {
        label: this.translateService.instant('PM'),
        value: TimeTypes.PM,
      }
    ]
  }

  onTimeTypeClicked(isStartTime: boolean): void {
    if (isStartTime && !this.startDateTime) {
      return;
    }

    if (!isStartTime && !this.endDateTime) {
      return;
    }

    /**
     * Is start time?
     * Change the time type and modify the respective date
     * Run validation
     */

    if (isStartTime) {
      const currentHours: number = this.startDateTime.getHours();
      if (this.activeStartTimeSelectOption === this.activeStartTimeOption) {
        return;
      }

      this.activeStartTimeSelectOption === TimeTypes.PM ? this.startDateTime.setHours(currentHours + 12) : this.startDateTime.setHours(currentHours - 12);
      this.activeStartTimeOption = this.activeStartTimeSelectOption;

      this.onTimeRangeChange();
    }

    if (!isStartTime) {
      const currentHours: number = this.endDateTime.getHours();
      if (this.activeEndTimeSelectOption === this.activeEndTimeOption) {
        return;
      }

      this.activeEndTimeSelectOption === TimeTypes.PM ? this.endDateTime.setHours(currentHours + 12) : this.endDateTime.setHours(currentHours - 12);
      this.activeEndTimeOption = this.activeEndTimeSelectOption;
      this.onTimeRangeChange();
    }

    this.cd.detectChanges();
  }
}
