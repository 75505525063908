import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, first, from, switchMap } from 'rxjs';
import { UserAuthenticationService } from '../../auth/services/user-authentication.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private userAuthenticationService: UserAuthenticationService) {}

  intercept = (
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> => {
    // Check if request to backend
    if (
      request.url.startsWith('/api/') ||
      request.url.startsWith(environment.reportingApiUrl) ||
      request.url.startsWith(environment.dashboardApiUrl)
    ) {
      return from(this.userAuthenticationService.getAuthorizationToken()).pipe(
        first(),
        switchMap((idToken) => {
          if (!idToken?.length) {
            return next.handle(request);
          }

          const cloned = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${idToken}`),
          });

          return next.handle(cloned);
        }),
      );
    }

    return next.handle(request);
  };
}
